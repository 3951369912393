import pictoExchange from '@assets/svg/picto-app-exchange-hours.svg'
import pictoNotif from '@assets/svg/picto-app-notif.svg'
import pictoPlanning from '@assets/svg/picto-app-planning.svg'
import pictoRealTime from '@assets/svg/picto-app-real-time.svg'
import pictoRequest from '@assets/svg/picto-app-request.svg'

const functionalities = [
    {
        id: 1,
        subTitle: 'Consultez vos heures de travail',
        title: 'Accès et consultation de votre propre planning',
        image: pictoPlanning,
        list: [
            'Consultez les mises à jour de votre planning',
            'Recevez des notifications à chaque modification',
            "Faites des demandes d'ajustement d'horaires",
        ],
    },
    {
        id: 2,
        subTitle: 'Gagnez en souplesse',
        title: 'Partage de vos disponibilités en temps réel',
        image: pictoRealTime,
        list: [
            'Indiquez vos éventuelles disponibilités pendant vos jours de repos',
            'Soyez notifié des créneaux à pourvoir instantanément',
            'Partagez vos indisponibilités pour éviter les sur sollicitations',
        ],
    },
    {
        id: 3,
        subTitle: 'Améliorez vos conditions de travail',
        title: 'Déclaration de vos demandes en ligne',
        image: pictoRequest,
        list: [
            'Sollicitez des modifications de vos horaires de travail',
            "Demandez vos congés directement sur l'application",
            'Transmettez vos arrêts maladies dématérialisés',
        ],
    },
    {
        id: 4,
        subTitle: 'Faites-vous remplacer',
        title: "Échanges d'horaires avec votre équipe",
        image: pictoExchange,
        list: [
            "Demandez un remplacement sur l'application",
            "Permutez vos horaires via la bourse d'échanges",
            'Répondez à des demandes de remplacement',
        ],
    },
    {
        id: 5,
        subTitle: 'Restez à jour',
        title: 'Notifications immédiates',
        image: pictoNotif,
        list: [
            'Soyez averti à chaque évolution de votre planning',
            'Recevez instantanément les créneaux à pourvoir',
            "Obtenez vos validations de congés sur l'application",
        ],
    },
]

export default functionalities
