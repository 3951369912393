import flexibility from '@assets/svg/illustration-flexibility.svg'
import live from '@assets/svg/illustration-live.svg'
import time from '@assets/svg/illustration-time.svg'

const mission = [
    {
        id: 1,
        title: 'Gain de temps',
        content: [
            'Gagnez du temps sur vos tâches grâce aux bénéfices de Staffea. Votre planning est consultable en temps réel, mois par mois. Recevez des notifications instantanées en cas de modification sur vos horaires de travail. Demandez des ajustements ou un remplacement en cas de besoin.',
            "Vous ne pouvez pas venir travailler ? Téléchargez votre arrêt maladie dématérialisé directement sur l'application pour économiser du temps, de l'énergie et du papier. Faites une demande ",
        ],
        svg: time,
    },
    {
        id: 2,
        title: 'Flexibilité',
        content: [
            "Staffea apporte de la flexibilité dans votre quotidien d'aide soignant. Si vous avez besoin d'être remplacé sur un de vos jours de travail, l'application va vous aider. Sollicitez un remplacement via la bourse d'échange, pour trouver automatiquement un collègue disponible.",
            "Vos collègues en disponibilité reçoivent une notification sur ce créneau, et n'ont plus qu'à accepter ou refuser l'échange ! Le planning sera automatiquement mis à jour, sans avoir besoin d'y apporter de modification manuelle.",
        ],
        svg: flexibility,
    },
    {
        id: 3,
        title: 'Planning en temps réel',
        content: [
            "Ne vous trompez plus dans vos roulements. Votre planning est mis à jour en temps réel et vous recevez des notifications push à chaque évolution, pour être prévenu de tout changement d'horaire vous concernant.",
            "Votre modification de planning ne vous convient pas ? Utilisez l'application pour faire une demande d'ajustement ou de remplacement. Tout est si simple avec Staffea !",
        ],
        svg: live,
    },
]

export default mission
